import { ActionTrigger } from '@fox/shared-util-ngrx';

import { loadAllModules, loadModuleById } from './modules.actions';

export const triggerLoadAllModules: ActionTrigger = () =>
  loadAllModules.action();

export const triggerLoadModuleById: ActionTrigger = (router) =>
  loadModuleById.action({
    payload: {
      id: router.paramMap.get('moduleId') ?? '',
    },
  });
