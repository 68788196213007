import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import {
  effectForApiCall,
  effectForParameterizedApiCall,
} from '@fox/shared-util-ngrx';
import {
  CreateModuleRequest,
  CreateModuleVersionRequest,
  Module,
  ModulesService,
} from '@fox/shared/api-administration';

import * as ModulesActions from './modules.actions';
import {
  createModule,
  createModuleVersion,
  loadModuleById,
} from './modules.actions';

@Injectable()
export class ModulesEffects {
  loadAllModules$ = effectForApiCall<Module[]>(
    ModulesActions.loadAllModules,
    this.actions$,
    () => this.service.moduleControllerGetAll()
  );

  createModule$ = effectForParameterizedApiCall<CreateModuleRequest, Module>(
    createModule,
    this.actions$,
    (module) => this.service.moduleControllerCreate(module)
  );

  createModuleVersion$ = effectForParameterizedApiCall<
    CreateModuleVersionRequest,
    Module
  >(createModuleVersion, this.actions$, (module) =>
    this.service.moduleControllerCreateVersion(module.configurationId, module)
  );

  loadModuleById$ = effectForParameterizedApiCall<{ id: string }, Module>(
    loadModuleById,
    this.actions$,
    (payload) => this.service.moduleControllerGet(payload.id)
  );

  routeToBuilderId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createModule.success, createModuleVersion.success),
        map((action) => action.payload),
        tap((module) => {
          this.router.navigate(['modules', 'builder', module._id]);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private service: ModulesService,
    private router: Router
  ) {}
}
