import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Module } from '@fox/shared/api-administration';

import * as ModulesActions from './modules.actions';

export const MODULES_FEATURE_KEY = 'modules';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends EntityState<Module> {
  selectedConfigurationId?: string;
}

export const modulesAdapter = createEntityAdapter<Module>({
  selectId: ({ configurationId }: Module) => configurationId,
});

export const initialState: State = modulesAdapter.getInitialState({});

const modulesReducer = createReducer(
  initialState,
  on(ModulesActions.loadAllModules.success, (state, { payload }) =>
    modulesAdapter.setAll(payload, {
      ...state,
      selectedConfigurationId: undefined,
    })
  ),
  on(
    ModulesActions.createModule.success,
    ModulesActions.createModuleVersion.success,
    (state, { payload }) =>
      modulesAdapter.upsertOne(payload, {
        ...state,
        selectedConfigurationId: undefined,
      })
  ),
  on(ModulesActions.loadModuleById.success, (state, { payload }) =>
    modulesAdapter.upsertOne(payload, {
      ...state,
      selectedConfigurationId: payload.configurationId,
    })
  )
);

export const reducer = (state: State | undefined, action: Action) =>
  modulesReducer(state, action);
