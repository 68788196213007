import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { unset } from 'lodash-es';

import {
  CreateModuleRequest,
  CreateModuleVersionRequest,
  Module,
} from '@fox/shared/api-administration';

import * as ModulesActions from './modules.actions';
import { State } from './modules.reducer';
import { selectAllModules, selectSelectedModule } from './modules.selectors';

@Injectable({ providedIn: 'root' })
export class ModulesFacade {
  allModules$ = this.store.pipe(select(selectAllModules));
  selectedModule$ = this.store.pipe(select(selectSelectedModule));

  constructor(private store: Store<State>) {}

  upsert(module: Module | CreateModuleRequest) {
    unset(module, 'resolvedDependencies');
    Object.prototype.hasOwnProperty.call(module, 'configurationId')
      ? this.store.dispatch(
          ModulesActions.createModuleVersion.action({
            payload: module as CreateModuleVersionRequest,
          })
        )
      : this.store.dispatch(
          ModulesActions.createModule.action({
            payload: module as CreateModuleRequest,
          })
        );
  }
}
