import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ListItemClicked } from '@assecosolutions/fox-navigation-bar';
import { ModulesFacade } from '@fox/shared-data-access-modules';
import { Module } from '@fox/shared/api-administration';

@Component({
  selector: 'fox-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  modules$ = this.modulesFacade.allModules$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modulesFacade: ModulesFacade
  ) {}

  onListItemClicked(event: ListItemClicked<Module>) {
    this.router.navigate(['modules', event.detail?.item?._id]);
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavigationComponentModule {}
