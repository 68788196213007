import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MODULES_FEATURE_KEY, modulesAdapter, State } from './modules.reducer';

export const state = createFeatureSelector<State>(MODULES_FEATURE_KEY);

const { selectAll, selectEntities } = modulesAdapter.getSelectors();

export const selectAllModules = createSelector(state, (state: State) =>
  selectAll(state)
);

export const selectModuleEntities = createSelector(state, (state: State) =>
  selectEntities(state)
);

export const selectSelectedConfigurationId = createSelector(
  state,
  (state: State) => state.selectedConfigurationId
);

export const selectSelectedModule = createSelector(
  selectModuleEntities,
  selectSelectedConfigurationId,
  (entities, id) => id && entities[id]
);
