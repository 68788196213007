import { ApiCall, ParameterizedApiCall } from '@fox/shared-util-ngrx';
import {
  CreateModuleRequest,
  CreateModuleVersionRequest,
  Module,
} from '@fox/shared/api-administration';

const scope = 'Modules';

export const loadAllModules = new ApiCall<Module[]>(
  scope,
  'Load All Modules ',
  {
    notification: {
      onFailure: 'Load all Modules failed',
    },
  }
);

export const loadModuleById = new ParameterizedApiCall<{ id: string }, Module>(
  scope,
  'Load module by id',
  {
    notification: {
      onFailure: `[${scope}] Load page by id`,
    },
  }
);

export const createModule = new ParameterizedApiCall<
  CreateModuleRequest,
  Module
>(scope, 'Create Module', {
  notification: {
    onFailure: 'Module creation failed',
    onSuccess: 'Module successfully created',
  },
});

export const createModuleVersion = new ParameterizedApiCall<
  CreateModuleVersionRequest,
  Module
>(scope, 'Create Module Version', {
  notification: {
    onFailure: 'Module update failed',
    onSuccess: 'Module update successful',
  },
});
