import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ModulesEffects } from './modules.effects';
import { ModulesFacade } from './modules.facade';
import * as fromModules from './modules.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromModules.MODULES_FEATURE_KEY,
      fromModules.reducer
    ),
    EffectsModule.forFeature([ModulesEffects]),
  ],
  providers: [ModulesFacade],
})
export class SharedDataAccessModulesModule {}
