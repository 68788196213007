import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ClientSharedFeatureNavigationModule } from '@fox/client/shared/feature-navigation';

import { DefaultLayoutComponent } from './default-layout.component';

@NgModule({
  declarations: [DefaultLayoutComponent],
  imports: [CommonModule, RouterModule, ClientSharedFeatureNavigationModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DefaultLayoutModule {}
