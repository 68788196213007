import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SharedUiDialogModule } from '@assecosolutions/ng-dialog';
import { SharedUiMenuModule } from '@assecosolutions/ng-menu';
import { CombinedRouterStateSerializer } from '@fox/shared-util-ngrx';
import { ApiModule, Configuration } from '@fox/shared/api-administration';
import { SharedDataAccessNotificationsModule } from '@fox/shared/data-access-notifications';
import { SharedUiNotificationsModule } from '@fox/shared/ui-notifications';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './shared/default/default-layout.component';
import { DefaultLayoutModule } from './shared/default/default-layout.module';
import { TenantIdInterceptor } from './shared/tenant-id.interceptor';

const ROUTES: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    loadChildren: () =>
      import('@fox/client/pages/feature-overview').then(
        (m) => m.ClientPagesFeatureOverviewModule
      ),
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    DefaultLayoutModule,
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {}
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CombinedRouterStateSerializer,
    }),
    EffectsModule.forRoot([]),
    RouterModule.forRoot(ROUTES, { paramsInheritanceStrategy: 'always' }),
    HttpClientModule,

    ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.apiUrl,
        })
    ),
    SharedUiMenuModule,
    SharedUiDialogModule,
    SharedUiNotificationsModule,
    SharedDataAccessNotificationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TenantIdInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
