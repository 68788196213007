import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NavigationComponentModule } from './navigation/navigation.component';

@NgModule({
  imports: [CommonModule, NavigationComponentModule],
  exports: [NavigationComponentModule],
})
export class ClientSharedFeatureNavigationModule {}
